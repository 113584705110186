import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dl-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressComponent {
  @Input() public progress = 0;
  public constructor() {}
}
