import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OneTopbarModule } from '@one/angular-kit/navigation';
import { OneIconModule } from '@one/angular-kit/icon';
import { environment } from '../environments/environment';
import { AppRouterModule } from './app-router.module';
import { AppComponent } from './app.component';
import { FooterModule } from './features/footer/footer.module';
import { HeaderModule } from './features/header/header.module';
import { ENVIRONMENT } from './core/models/environment.token';
import { FeatureFlagModule } from './core/feature-flag/feature-flag.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { appInitializerProvider } from './core/app-initializer/app-initializer.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDividerModule } from '@angular/material/divider';
import { OneSnackBarModule } from '@one/angular-kit/modal';
import { MatListModule } from '@angular/material/list';
import { OneListModule } from '@one/angular-kit/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppEnablerModule } from './features/app-enabler/app-enabler.module';
import { RouterModule } from '@angular/router';
import { ModalService } from './core/services/modal.service';
import { SoftwareDashboardsModule } from './features/software/software-dashboards.module';
import { SoftwareDownloadErrorHandlingInterceptor } from './features/interceptors/software-download-error-handling.interceptor';
import { loadingInterceptorProvider } from '@dialog-eservices-enablement/angular-components';
import { LoadingBarModule } from '@dialog-eservices-enablement/angular-components';


@NgModule({
  declarations: [AppComponent],
  imports: [SoftwareDashboardsModule, BrowserModule, RouterModule, BrowserAnimationsModule, AppRouterModule, HammerModule, HttpClientModule, OneIconModule, OneTopbarModule, HeaderModule, FooterModule, MatBottomSheetModule, MatTooltipModule, FeatureFlagModule, MatDividerModule, OneSnackBarModule, MatListModule, OneListModule, MatSidenavModule, AppEnablerModule,LoadingBarModule],
  providers: [
    appInitializerProvider,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    ...environment.providers,
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: APP_BASE_HREF,
      useFactory: (platformLocation: PlatformLocation) => platformLocation.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: SoftwareDownloadErrorHandlingInterceptor, multi: true
    },
    ModalService,
    loadingInterceptorProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
