import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT } from '../models/environment.token';
import { EnvironmentInterface } from '../models/environment.interface';

export interface FeatureFlags {
  favorites: boolean;
  groups: boolean;
  getHelp: boolean;
  welcomePopup: boolean;
}

export type FeatureFlag = keyof FeatureFlags;

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  public featureFlags: FeatureFlags = this.environment.featureFlags;
  private url = 'https://jsonplaceholder.typicode.com/todos/1';
  public constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: EnvironmentInterface) {}

  /*  public getFeatureFlags(): Observable<FeatureFlags> {
    const mockResponse = {
      groups: true
    };

    return this.http
      .get(this.url)
      .pipe(
        delay(0),
        mapTo(mockResponse),
        tap(featureFlags => (this.featureFlags = featureFlags))
      )
  }*/

  public hasFlag(featureFlag: FeatureFlag): boolean {
    return Boolean(this.featureFlags[featureFlag]);
  }
}
