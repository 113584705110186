import { Inject, Injectable } from '@angular/core';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { ENVIRONMENT } from '../models/environment.token';
import { EnvironmentInterface } from '../models/environment.interface';

export interface Bundle {
  languageCode: string;
  countryCode: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChangeBundleService {
  public constructor(@Inject(ENVIRONMENT) private environment: EnvironmentInterface, private oneSnackBarService: OneSnackBarService) {}

  public redirectToDefaultBundle(): void {
    window.location.href = this.environment.appUrl;
  }
  // Pass in affiliates to make sure they are available, because this method is called in APP_INITIALIZER
  public async redirectToTranslatedBundle(bundle: Bundle): Promise<void> {
    bundle = await this.checkAffiliateRedirections(bundle);
    if (!this.doesTranslationFileExist(bundle)) {
      this.oneSnackBarService.open(`No translation for ${bundle.countryCode}/${bundle.languageCode}`, OneSnackBarType.ERROR);
      return;
    }
    if (!(await this.isLanguageSupportedByAffiliate(bundle))) {
      this.oneSnackBarService.open(`${bundle.languageCode} is not supported by affiliate ${bundle.countryCode}`, OneSnackBarType.ERROR);
      return;
    }

    window.location.href = this.environment.appUrl + `/dialog/${bundle.countryCode.toLowerCase()}/${bundle.languageCode.toLowerCase()}/index.html`;
  }
  // Algeria has to be redirected to the affiliate France, because Algeria does not have its own affiliate
  private async checkAffiliateRedirections(bundle: Bundle): Promise<Bundle> {
    const hasToBeRedirected = bundle.countryCode.toLowerCase() in this.environment.redirectAffiliate;
    if (!hasToBeRedirected) {
      return bundle;
    } else {
      const affiliateToRedirectTo = this.environment.redirectAffiliate[bundle.countryCode];
      // .affiliates is already loaded when the regular app runs, but since this is also called from APP_INITIALIZER, let's make sure it is loaded.
      // let affiliates: Affiliate[];
      let affiliates;
      for (const affiliate of affiliates) {
        if (affiliate.affiliate.toLowerCase() === affiliateToRedirectTo.toLowerCase()) {
          return {
            countryCode: affiliateToRedirectTo,
            languageCode: affiliate.defaultLanguage.toLowerCase()
          };
        }
      }
      console.warn('Missing affiliate');
    }
  }
  // Check if we have a matching messages.xlf translation file
  private doesTranslationFileExist(bundle: Bundle): boolean {
    return bundle.countryCode in this.environment.availableLocales && this.environment.availableLocales[bundle.countryCode.toLowerCase()] && this.environment.availableLocales[bundle.countryCode.toLowerCase()][bundle.languageCode.toLowerCase()];
  }
  // Check if the language is supported by the affiliate
  private async isLanguageSupportedByAffiliate(bundle: Bundle): Promise<boolean> {
    // let affiliates: Affiliate[];
    let affiliates;
    const affiliate = affiliates.find(a => a.affiliate.toLowerCase() === bundle.countryCode.toLowerCase());
    for (const supportedLanguage of affiliate.availableLanguages) {
      if (supportedLanguage.toLowerCase() === bundle.languageCode.toLowerCase()) {
        return true;
      }
    }
    return false;
  }
}
