import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../../core/auth/auth.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ENVIRONMENT } from '../../../core/models/environment.token';
import { EnvironmentInterface } from '../../../core/models/environment.interface';
import { ChangeBundleService } from '../../../core/bundle/change-bundle.service';
import { DetermineBundleService } from '../../../core/bundle/determine-bundle.service';
import { FeatureFlagService } from '../../../core/feature-flag/feature-flag.service';
import { OneSnackBarService } from '@one/angular-kit/modal';
import { OpenLinkWithReferrerService } from '../../../core/auth/open-link-with-referrer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'dl-double-global-area',
  templateUrl: './double-global-area.component.html',
  styleUrls: ['./double-global-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class DoubleGlobalAreaComponent implements OnInit {
  
  public constructor(@Inject(ENVIRONMENT) private environment: EnvironmentInterface, private router: Router, private breakpointObserver: BreakpointObserver, private authService: AuthService, private changeBundleService: ChangeBundleService, private determineBundleService: DetermineBundleService, private featureService: FeatureFlagService, private oneSnackBarService: OneSnackBarService, private changeDetectorRef: ChangeDetectorRef, private openLinkWithReferrerService: OpenLinkWithReferrerService) {}
  public profileData: any;
  public userInitials: string;
  public ngOnInit(): void {
    this.authService.getCurrentUser().then(u => {
      this.profileData = u;
      this.userInitials = u?.givenName?.substring(0, 1);
    });
  }

  public expandUserProfile = false;
  
  public redirectToHome():void {
    this.router.navigateByUrl('/app-enabler');
  }

  public reDirectHome(availableLocale?: object): string {
    let currentPath = window.location.href;
    let trimPath =  currentPath.slice(0, currentPath.lastIndexOf('/'));

    return trimPath +'/app-enabler';
  }

  public logout() {
    this.authService.signOut();
  }
}
