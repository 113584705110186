import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as softwareComponents from './components';
import { MaterialModule } from '../../material/material.module';
import { FileListModule } from '../file-list/file-list.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { OneTableModule } from '@one/angular-kit/data-table';
import { AddFilesModule } from '../add-files/add-files.module';

@NgModule({
  declarations: [...softwareComponents.allComponents],
  exports: [softwareComponents.SoftwareDashboardComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, RouterModule, OneTableModule, MatSortModule, MatSlideToggleModule, AddFilesModule, FileListModule]
})
export class SoftwareDashboardsModule {}
