<ng-container>
  <mat-toolbar one-double-area-toolbar oneTopbar class="qnsbn-double-global-topbar">
    <mat-toolbar-row one-double-area-row>
      <a href="https://diagnostics.roche.com/" title="Roche Diagnostics" target="_blank" one-topbar-title class="active" i18n="@@topbar.product-title">Roche Diagnostics</a>
      <a href="https://dialogportal.roche.com/" title="Dialog Portal" target="_blank" one-topbar-title i18n="@@topbar.product-title">DiaLog Portal</a>
      <span class="spacer"></span>

      <!-- <button color="primary" mat-flat-button (click)="testLSOLogin()">
        <mat-icon>standby</mat-icon>
        <span>Test Login</span>
      </button> -->
      <button [matMenuTriggerFor]="profileMenu" color="primary" mat-flat-button one-topbar-profile-button>
        <mat-icon>user</mat-icon>
        <span>{{ profileData?.name }}</span>
      </button>
      <mat-menu #profileMenu="matMenu" xPosition="before" class="user-profile-dialog">
        <div class="profile-menu-topbar">
          <div class="icon-profile-menu-topbar">{{ userInitials }}</div>
          <div class="info-profile-menu-topbar">
            <div class="display-name-profile-menu-topbar">{{ profileData?.name }}</div>
            <span class="mat-caption">{{ profileData?.email }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <button class="btn-logout" mat-menu-item (click)="logout()">
          <mat-icon>standby</mat-icon>
          <span>Logout</span>
        </button>
        <mat-divider></mat-divider>
        <!-- <button mat-menu-item>
          <mat-icon>user</mat-icon>
          <span>Title</span>
        </button>
        <button mat-menu-item>
          <mat-icon>settings</mat-icon>
          <span>Title</span>
        </button> -->
      </mat-menu>
      <!-- <mat-divider [vertical]="true" class="one-vertical-divider"></mat-divider> -->
      <mat-icon class="eservice-mat-globe-icon" mat-list-icon style="padding: 5px 10px;">globe</mat-icon>
      <span one-topbar-title i18n="@@topbar.product-title">Global/EN</span>
    </mat-toolbar-row>
    <mat-toolbar-row>
      <img (click)="redirectToHome()" title="App Enabler" alt="Icon placeholder" class="icon-placeholder" src="assets/cobas_liat_download.svg" style="cursor:pointer;"/>
      <span (click)="redirectToHome()" title="Software Update" one-topbar-title i18n="@@topbar.product-title" style="cursor:pointer;">cobas<sup>®</sup> liat Software Update</span>
      <span class="topbar-spacer"></span>
      <div class="mat-tab-link-container">
        <div class="mat-tab-list" style="transform: translateX(0px);">
          <!--div class="mat-tab-links" (click)="redirectToHome()">
            <a active="true" class="mat-tab-link mat-focus-indicator mat-tab-label-active"
              mat-tab-link="" aria-current="page" aria-disabled="false" tabindex="0" title="Notification"> Menu </a>
          </div>
          <span class="mat-ink-bar"></span-->
            <nav mat-tab-nav-bar >
              <a mat-tab-link [routerLink]="['/software-list']" routerLinkActive #rla1="routerLinkActive" [active]="rla1.isActive" class="mat-tab-link mat-focus-indicator "
                mat-tab-link aria-current="page" aria-disabled="false" tabindex="0" title="Software"> Core Software </a>
              <a mat-tab-link [routerLink]="['/script-list']" routerLinkActive #rla2="routerLinkActive" [active]="rla2.isActive" class="mat-tab-link mat-focus-indicator"
                mat-tab-link aria-current="page" aria-disabled="false" tabindex="1" title="Scripts"> Assay Scripts </a>
            </nav>
        </div>
      </div>
      <span class="spacer"></span>
      <!-- <button one-icon-button mat-icon-button>
        <mat-icon>settings</mat-icon>
      </button>
      <button one-icon-button mat-icon-button>
        <mat-icon>help</mat-icon>
      </button> -->
      <a href="https://diagnostics.roche.com/" title="Liat Software Download" target="_blank" style="align-items: center; display: flex;">
        <img alt="Liat logo" class="liat-logo" src="assets/cobas_liat.svg" />
        <img alt="Roche logo" class="roche-logo" src="assets/logo_roche.svg" />
      </a>
    </mat-toolbar-row>
  </mat-toolbar>
</ng-container>
