import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '../models/environment.token';
import { EnvironmentInterface } from '../models/environment.interface';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  public constructor(
    private authService: AuthService, 
    @Inject(ENVIRONMENT) private environment: EnvironmentInterface) {}

  public initApp(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.determineAppUrls(); 
      this.authService.init()
        .then(() => resolve())
        .catch(() => reject());
    });
  }

  private determineAppUrls() {
    const appUrl = window.location.origin;
    this.environment.appUrl = appUrl;
    this.environment.appApiUrl = `${appUrl}/apiproxy`;
    this.environment.apiUrl = `${appUrl}/softwaredistribution`;
  }
}

export const appInitializerProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (appInitializerService: AppInitializerService) => () => appInitializerService.initApp(),
  deps: [AppInitializerService]
};
