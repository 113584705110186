import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OneSnackBarService } from '@one/angular-kit/modal';
import { UserDataService } from 'projects/dialog/src/app/core/services/user-data.service';
import { SaveGlobalAsLocal } from 'projects/dialog/src/app/shared/models/local.model';
import { LocalPackageService } from '../../../services/local-package.service';

@Component({
  selector: 'dl-deploy-file-modal',
  templateUrl: './deploy-file-modal.component.html',
  styleUrls: ['./deploy-file-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DeployFileModalComponent {
  public deployForm: FormGroup;

  public constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<DeployFileModalComponent>, private userDataService: UserDataService, private localservice: LocalPackageService, private oneSnackBarService: OneSnackBarService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.deployForm = this.fb.group({
      packageName: [{ value: this.data.packageName, disabled: true }],
      description: [{ value: this.data.packageDescription, disabled: true }],
      localDescription: ['', Validators.required]
    });
  }

  public deployFile(): void {
    const saveGlobalAsLocal: SaveGlobalAsLocal = {
      globalPackageId: this.data.globalPackageId,
      country: this.userDataService.getCountry().toUpperCase(),
      localPackageDescription: this.deployForm.controls.localDescription.value
    };
    this.localservice.createPackage(saveGlobalAsLocal).subscribe(result => {
      this.dialogRef.close(result);
    });
  }
}
