import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UserDataService } from 'projects/dialog/src/app/core/services/user-data.service';
import { PackageType } from 'projects/dialog/src/app/shared/models/core.types';

@Component({
  selector: 'dl-software-dashboard',
  templateUrl: './software-dashboard.component.html',
  styleUrls: ['./software-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SoftwareDashboardComponent implements OnInit {
  public packageType: PackageType = 'Core';

  public constructor(public userDataService: UserDataService) {}

  public ngOnInit(): void {}
}
