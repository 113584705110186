import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppUtils } from '../../shared/utilities/app-utils';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  public constructor(private http: HttpClient) {}

  public uploadFile(signedUrl:string,file:File){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', new AppUtils().getMimeType(file.name));
    return this.http.put(signedUrl, file, { headers, reportProgress: true, observe: 'events' })
  }

}
