import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppEnablerFirstComponent } from './app-enabler-first/app-enabler-first.component';



@NgModule({
  declarations: [
    AppEnablerFirstComponent
  ],
  imports: [
    CommonModule
  ]
})
export class AppEnablerModule { }
