import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { ModalService } from 'projects/dialog/src/app/core/services/modal.service';
import { PackageType } from 'projects/dialog/src/app/shared/models/core.types';
import { GlobalPackage } from 'projects/dialog/src/app/shared/models/global.model';
import { LocalPackage } from 'projects/dialog/src/app/shared/models/local.model';
import { of, Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { DeployFileModalComponent, EditSoftwareModalComponent } from '../../../add-files/modals';
import { ChangeStatusModalComponent } from '../../../add-files/modals/change-status-modal/change-status-modal.component';
import { GlobalPackageService } from '../../../services/global-package.service';
import { LocalPackageService } from '../../../services/local-package.service';

@Component({
  selector: 'dl-affiliate-user-dashboard',
  templateUrl: './affiliate-user-dashboard.component.html',
  styleUrls: ['./affiliate-user-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AffiliateUserDashboardComponent implements OnInit,OnDestroy {
  @Input()
  public packageType: PackageType;

  public globalPackages?: GlobalPackage[];
  public localPackages?: LocalPackage[];
  private destroy$ = new Subject();

  public constructor(private globalPackageService: GlobalPackageService, private localPackageService: LocalPackageService, private modalService: ModalService, private oneSnackBarService: OneSnackBarService) {}

  public ngOnInit(): void {
    this.globalPackageService.refreshGlobalPackages();
    this.localPackageService.refreshLocalPackages();
    this.localPackageService.getLocalPackage().pipe(
      takeUntil(this.destroy$),
      mergeMap(localPackages => {
      this.localPackages = localPackages?.filter(localPackage => {
        return localPackage.type === this.packageType;
      });
      this.globalPackageService.getGlobalPackage().pipe(takeUntil(this.destroy$)).subscribe(globalPackages => {
        this.globalPackages = globalPackages?.filter(globalPackage => globalPackage.type === this.packageType && localPackages?.filter(localPackage => localPackage.globalPackageId === globalPackage.globalPackageId).length === 0);
      })
      return of(localPackages);
    })).subscribe();
  }

  public changeStatus(data: any): void {
    const modalRef = this.modalService.showModal<ChangeStatusModalComponent, any, any>(ChangeStatusModalComponent, data);

    modalRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.localPackageService.changeStatus(data.editedPackage.localPackageId, data.newStatus).subscribe(localPackage => {
        this.localPackageService.refreshLocalPackages();
        this.oneSnackBarService.open('Success', OneSnackBarType.SUCCESS);
      });
    });
  }

  public editPackage(localPackage: LocalPackage): void {
    const ref = this.modalService.showModal<EditSoftwareModalComponent, any, any>(EditSoftwareModalComponent, { package: localPackage, packageType: this.packageType, type: 'LOCAL' });
    ref.afterClosed().subscribe(el => {
      if (el) {
        this.localPackageService.refreshLocalPackages();
        this.oneSnackBarService.open('Success', OneSnackBarType.SUCCESS);
      }
    });
  }

  public createLocalPackage(globalPackage: GlobalPackage): void {
    const ref = this.modalService.showModal<DeployFileModalComponent, any, any>(DeployFileModalComponent, globalPackage);
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.localPackageService.refreshLocalPackages();
        this.globalPackageService.refreshGlobalPackages();
        this.oneSnackBarService.open('Success', OneSnackBarType.SUCCESS);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
