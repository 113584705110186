import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/dialog/src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserDataService } from '../../core/services/user-data.service';
import { PackageStatusStateMachine } from '../../shared/models/core.types';
import { LocalPackage, SaveGlobalAsLocal, UpdateInternalLocal } from '../../shared/models/local.model';

@Injectable({
  providedIn: 'root'
})
export class LocalPackageService {
  private readonly gUrl = environment.apiUrl + '/';

  public constructor(private http: HttpClient, private userDataService: UserDataService) {}

  public localPackages: BehaviorSubject<LocalPackage[]> = new BehaviorSubject(undefined);

  getLocalPackage() {
    return this.localPackages.asObservable();
  }

  setLocalPackage(packageData) {
    this.localPackages.next(packageData);
  }

  public refreshLocalPackages(): void {
    this.getPackageList().subscribe(localPackages => this.localPackages.next(localPackages));
  }

  public createPackage(saveGlobalAsLocal: SaveGlobalAsLocal): Observable<LocalPackage> {
    const url = `${this.gUrl}v1/internal/package/local`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.post<LocalPackage>(url, saveGlobalAsLocal, { headers });
  }

  public updatePackage(localPackageId: string, updateInternalLocal: UpdateInternalLocal): Observable<LocalPackage> {
    const url = `${this.gUrl}v1/internal/package/local/${localPackageId}`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.put<LocalPackage>(url, updateInternalLocal, { headers });
  }

  public changeStatus(localPackageId: string, status: PackageStatusStateMachine): Observable<LocalPackage> {
    const url = `${this.gUrl}v1/internal/package/local/${localPackageId}`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.patch<LocalPackage>(url, { status }, { headers });
  }

  public getPackageList(): Observable<LocalPackage[]> {
    const url = `${this.gUrl}v1/internal/package/local`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.get<LocalPackage[]>(url, { headers });
  }

  public getPackageDetails(localPackageId: string): Observable<LocalPackage> {
    const url = `${this.gUrl}v1/internal/package/local/${localPackageId}`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.get<LocalPackage>(url, { headers });
  }

  public downloadPackage(localPackageId: string, filename: string): Observable<Response> {
    const url = `${this.gUrl}v1/internal/package/local/${localPackageId}/file`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.get<Response>(url, { headers, params: { filename } });
  }

  private createHeader(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${this.userDataService.getAccessToken()}`);
    return headers;
  }
}
