<div class='software-download-dashboard-container'>
    <div class='software-download-dashboard--form-panel text-left'>
      <mat-card class='mat-card software-download-dashboard--mat-card'>
        <ng-container *ngIf='userDataService.getUserRole() === "GLOBAL"'>
          <mat-card-title class='mat-card-title title'>
            <dl-software-dashboard-title [text]='"Global Software Admin"'></dl-software-dashboard-title>
          </mat-card-title>
          <dl-global-user-dashboard [packageType]="packageType"></dl-global-user-dashboard>
        </ng-container>

        <ng-container *ngIf='userDataService.getUserRole() === "AFFILIATE"'>
          <mat-card-title class='mat-card-title title'>
            <dl-software-dashboard-title [text]='"Affiliate User"'></dl-software-dashboard-title>
          </mat-card-title>
          <dl-affiliate-user-dashboard [packageType]="packageType"></dl-affiliate-user-dashboard>
        </ng-container>

        <ng-container *ngIf="userDataService.getUserRole() === 'NONE'">
          <mat-card-title class="mat-card-title title"> <dl-software-dashboard-title [text]="''">
            </dl-software-dashboard-title>
          </mat-card-title>
          <h2 class="software-message">
            Not enough permissions. Please, contact your local Roche support team for assistance.
          </h2>
        </ng-container>
      </mat-card>
    </div>
  </div>
