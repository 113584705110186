import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer-component/footer.component';
import { OneFooterModule } from '@one/angular-kit/navigation';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [FooterComponent],
  exports: [FooterComponent],
  imports: [CommonModule, MatButtonToggleModule, MatIconModule, OneFooterModule]
})
export class FooterModule {}
