<ng-container>
  <div class="main-container">
     <dl-double-global-area isDesktop="true"></dl-double-global-area>
     <dl-loading-bar></dl-loading-bar>
     <div class="main-page">
      <router-outlet page></router-outlet>
     </div>
     <dl-footer></dl-footer>
   </div>
</ng-container>

<!-- Translate text for usage in .ts files https://angular.io/guide/i18n#translate-text-without-creating-an-element -->
<div [hidden]="true">
  <ng-container #requestSuccess i18n="Request eservice success message | Message to be displayed when an user can successfully request an eservice@@core.user-e-services-service.requestSuccess" data-translation-id="core.user-e-services-service.requestSuccess">Access requested</ng-container>
  <ng-container #requestFail i18n="Request eservice failure message | Message to be displayed when an user can not request an eservice@@core.user-e-services-service.requestFail" data-translation-id="core.user-e-services-service.requestFail">Request not successful</ng-container>
  <ng-container #liveChatError i18n="Live chat error message | Message to be displayed when live chat cannot be enabled@@header.live-chat.live-chat-error" data-translation-id="header.live-chat.live-chat-error">Live chat cannot be enabled</ng-container>
  <ng-container #getHelpTicketSuccess i18n="Show help ticket success message|When help ticket successfully submitted@@get-help.get-help-ticket.submitTicketSuccess" data-translation-id="get-help.get-help-ticket.submitTicketSuccess">Your ticket has been submitted to REXIS. Please check REXIS for updates on your inquiry.</ng-container>
  <ng-container #getHelpTicketRequestTypeTechnicalIssue i18n="Type of request option Technical Issue|Type of request option Technical Issue@@get-help.get-help-ticket.requestTypeTechnicalIssue" data-translation-id="get-help.get-help-ticket.requestTypeTechnicalIssue">Technical issue</ng-container>
  <ng-container #getHelpTicketRequestTypeFeatureRequest i18n="Type of request option Feature request|Type of request option Feature request@@get-help.get-help-ticket.requestTypeFeatRequest" data-translation-id="get-help.get-help-ticket.requestTypeFeatRequest">Feature request</ng-container>
  <ng-container #getHelpTicketRequestTypeOther i18n="Type of request option Other|Type of request option Other@@get-help.get-help-ticket.requestTypeOther" data-translation-id="get-help.get-help-ticket.requestTypeOther">Other</ng-container>
</div>
