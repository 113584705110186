import { Injectable } from '@angular/core';
import { Cookie } from '@one/angular-kit/pattern/src/lib/cookie-acceptance/cookie-acceptance-service/cookie-acceptance-data.interface';
import { fromEvent, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

/*
Everything in the app that uses local storage should use this service, so we have
- an overview in one place of what is stored in local storage
- we can swap it out for a different implementation
TODO Search for "localStorage" throughout the codebase
 */

export interface RememberLocation {
  languageCode: string;
  countryCode: string;
}
enum LocalStorageKeys {
  rememberMyLocation = 'rememberMyLocation',
  userSelectedDoNotShowLoginDialog = 'userSelectedDoNotShowLoginDialog',
  preventingLoginDialogViaQueryParam = 'preventingLoginDialogViaQueryParam',
  cookies = 'cookies',
  accountActivationDialog = 'accountActivationDialog'
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private isTrue(localStorageKey: LocalStorageKeys): boolean {
    const value = localStorage.getItem(localStorageKey);
    // Internet Explorer returns "false" as a string if the key is not set.
    if (value === 'false') {
      return false;
    }
    return Boolean(value);
  }

  /**
   * As per https://roche.service-now.com/rm_story.do?sys_id=197569241b1b1c105d0199baab4bcb71&sysparm_view=&sysparm_view_forced=true&sysparm_time=1602752719319
   * We need to disable the login popup one off when the user is coming from a registration
   * To achieve this we set up a separate localstorage key which is used for one-time turn off
   */
  public preventLoginDialogViaQueryParam(): void {
    localStorage.setItem(LocalStorageKeys.preventingLoginDialogViaQueryParam, 'true');
  }

  public isLoginDialogPreventedViaQueryParam(): boolean {
    return this.isTrue(LocalStorageKeys.preventingLoginDialogViaQueryParam);
  }

  public loginDialogPreventedViaQueryParam(): void {
    localStorage.removeItem(LocalStorageKeys.preventingLoginDialogViaQueryParam);
  }

  // Login dialog
  public userSelectedDoNotShowLoginDialog(): void {
    localStorage.setItem(LocalStorageKeys.userSelectedDoNotShowLoginDialog, 'true');
  }

  public hasUserSelectedDoNotShowLoginDialog(): boolean {
    return this.isTrue(LocalStorageKeys.userSelectedDoNotShowLoginDialog);
  }

  // Remember location
  public rememberLocation(rememberLocation: RememberLocation): void {
    localStorage.setItem(LocalStorageKeys.rememberMyLocation, JSON.stringify(rememberLocation));
  }
  public forgetLocation(): void {
    localStorage.removeItem(LocalStorageKeys.rememberMyLocation);
  }
  public getRememberedLocation(): RememberLocation {
    return JSON.parse(localStorage.getItem(LocalStorageKeys.rememberMyLocation));
  }
  public isLocationRemembered(): boolean {
    return this.isTrue(LocalStorageKeys.rememberMyLocation);
  }

  // Cookies
  public setCookies(cookies: Cookie[]): void {
    localStorage.setItem(LocalStorageKeys.cookies, JSON.stringify(cookies));
  }
  public getCookies(): Cookie[] {
    return JSON.parse(localStorage.getItem(LocalStorageKeys.cookies));
  }

  // Pop-up message for users tagged with generic account
  public setAccountActivationVal(val: string): void {
    localStorage.setItem(LocalStorageKeys.accountActivationDialog, val);
  }

  public setAccountActivationInProgress(): void {
    localStorage.setItem(LocalStorageKeys.accountActivationDialog, 'false');
  }

  public getAccountActivationVal(): string {
    return localStorage.getItem(LocalStorageKeys.accountActivationDialog);
  }

  public getAccountActivationValChanges(): Observable<string> {
    return fromEvent<StorageEvent>(window, 'storage').pipe(
      filter(event => event.storageArea === localStorage),
      filter(event => event.key === LocalStorageKeys.accountActivationDialog),
      map(event => event.newValue)
    );
  }

  public getUserData(userDataKey: string): object {
    return JSON.parse(localStorage.getItem(userDataKey));
  }
}
