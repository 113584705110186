import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PackageStatusStateMachine } from 'projects/dialog/src/app/shared/models/core.types';
import { GlobalPackage } from 'projects/dialog/src/app/shared/models/global.model';

@Component({
  selector: 'dl-change-status-modal',
  templateUrl: './change-status-modal.component.html',
  styleUrls: ['./change-status-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeStatusModalComponent implements OnInit {
  public status: PackageStatusStateMachine;
  public editedPackage: GlobalPackage;

  public constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  public ngOnInit(): void {
    this.status = this.data.newStatus;
    this.editedPackage = this.data.editedPackage;
  }
}
