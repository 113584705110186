<div class="modal-container">
  <h2 matDialogTitle>Add local package</h2>

  <div class="deploy-file-form">
    <form [formGroup]='deployForm'>
      <mat-form-field class='example-full-width' class='deploy-form-field'>
        <mat-label class="black">Package name</mat-label>
        <input formControlName='packageName' matInput placeholder='Local Description which will appear on Customer page'>
      </mat-form-field>
          <mat-form-field class='example-full-width' class='deploy-form-field'>
        <mat-label class="black">Global package description</mat-label>
        <textarea formControlName='description' matInput cdkTextareaAutosize placeholder='Local Description which will appear on Customer page' cdkScrollable [cdkAutosizeMaxRows]="5" disabled></textarea>
      </mat-form-field>
      <mat-form-field class='example-full-width' class='deploy-form-field'>
        <mat-label class="black">Local package description</mat-label>
        <textarea formControlName='localDescription' matInput cdkTextareaAutosize placeholder='Local Description which will appear on Customer page' cdkScrollable [cdkAutosizeMaxRows]="5"></textarea>
      </mat-form-field>
    </form>  
  </div>

  <div class='mat-dialog-actions button-bar'>
    <button mat-stroked-button type='button' id='closeButton' color='primary' mat-dialog-close>Close</button>
    <button mat-raised-button type='button' id='acceptButton' color='primary' [disabled]='deployForm.controls.localDescription.invalid' (click)='deployFile()'>Add
      Package
    </button>
  </div>
</div>
