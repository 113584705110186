import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'dl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {

  public constructor(private sanitizer: DomSanitizer, private changeDetectorRef: ChangeDetectorRef) {}

  public sanitizeURL(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public ngOnInit(): void {

  }
}
