import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../../../core/services/modal.service';
import { AddSoftwareModalComponent, EditSoftwareModalComponent } from '../../../add-files/modals';
import { PackageType } from 'projects/dialog/src/app/shared/models/core.types';
import { GlobalPackage } from 'projects/dialog/src/app/shared/models/global.model';
import { GlobalPackageService } from '../../../services/global-package.service';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { ChangeStatusModalComponent } from '../../../add-files/modals/change-status-modal/change-status-modal.component';

@Component({
  selector: 'dl-global-user-dashboard',
  templateUrl: './global-user-dashboard.component.html',
  styleUrls: ['./global-user-dashboard.component.scss'],
  // changeDetection: ChangeDetectionStrategy.Default
})
export class GlobalUserDashboardComponent implements OnInit {
  @Input()
  public packageType: PackageType;

  public globalPackages: GlobalPackage[];

  public constructor(private globalPackageService: GlobalPackageService, private modalService: ModalService, private oneSnackBarService: OneSnackBarService) {}

  public ngOnInit(): void {
    if (this.globalPackageService.getGlobalPackageValue() === undefined) this.globalPackageService.refreshGlobalPackages();
    this.globalPackageService.getGlobalPackage().subscribe(globalPackages => (this.globalPackages = globalPackages?.filter(globalPackage => globalPackage.type === this.packageType)));
  }

  public addSoftware(): void {
    const modalRef = this.modalService.showModal<AddSoftwareModalComponent, any, any>(AddSoftwareModalComponent, { packageType: this.packageType });
    modalRef.afterClosed().subscribe(result => {
      if (result) {
        this.globalPackageService.refreshGlobalPackages();
        this.oneSnackBarService.open('Success', OneSnackBarType.SUCCESS);
      }
    });
  }

  public editPackage(editedPackage: GlobalPackage): void {
    const ref = this.modalService.showModal<EditSoftwareModalComponent, any, any>(EditSoftwareModalComponent, { package: editedPackage, packageType: this.packageType, type: 'GLOBAL' });
    ref.afterClosed().subscribe(el => {
      if (el) {
        this.globalPackageService.refreshGlobalPackages();
        this.oneSnackBarService.open('Success', OneSnackBarType.SUCCESS);
      }
    });
  }

  public changeStatus(data: any): void {
    const modalRef = this.modalService.showModal<ChangeStatusModalComponent, any, any>(ChangeStatusModalComponent, data);
    modalRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.globalPackageService.changeStatus(data.editedPackage.globalPackageId, data.newStatus).subscribe(globalPackage => {
        this.globalPackageService.refreshGlobalPackages();
        this.oneSnackBarService.open('Success', OneSnackBarType.SUCCESS);
      });
    });
  }
}
