<div *ngIf="softwarePackage" class="edit-software-form-modal">

  <h2 matDialogTitle>{{ softwarePackage.status === 'Added' ? 'Edit package' : 'Package Info' }}</h2>

  <div class="edit-software-form">
    <form [formGroup]="packageInformationForm">
      <div class="files-list">
        <div class="single-file">
          <div class="file-icon" style="width: 50px">
            <img src="assets/file-icon.svg" alt="File Icon">
          </div>
          <div class="info" *ngIf="softwarePackage.packageAttachments && softwarePackage.packageAttachments.length > 0">
            <h4 class="name">
              <a href="{{ downloadUrl }}">{{ (softwarePackage?.packageAttachments)[0].fileName }} </a>
            </h4>
            <p class="size">
              {{ formatBytes((softwarePackage?.packageAttachments)[0].fileSize) }}
            </p>
          </div>
        </div>
      </div>
  
      <mat-form-field class="example-full-width" style="width: 100%; margin-top: 21px">
        <mat-label>Package name</mat-label>
        <input formControlName="packageName" matInput placeholder="File name" [readonly]="type !== 'GLOBAL' && softwarePackage.status !== 'Added'"/>
      </mat-form-field>
  
      <mat-form-field class="example-full-width" style="width: 100%; margin-top: 21px">
        <mat-label>Package Type</mat-label>
        <input formControlName="type" matInput placeholder="Type" readonly />
      </mat-form-field>
  
      <mat-form-field class="example-full-width" style="width: 100%; margin-top: 21px">
        <mat-label [class.black]="type !== 'LOCAL' && softwarePackage.status === 'Added'">Version</mat-label>
        <input formControlName="version" matInput placeholder="Version" [readonly]="type === 'LOCAL'" />
      </mat-form-field>
  
      <mat-form-field class="example-full-width" style="width: 100%; margin-top: 21px">
        <mat-label [class.black]="type !== 'LOCAL' && softwarePackage.status === 'Added'">Description</mat-label>
        <textarea formControlName="description" matInput cdkTextareaAutosize placeholder="Description" [readonly]="type === 'LOCAL'" cdkScrollable [cdkAutosizeMaxRows]="5"></textarea>
      </mat-form-field>
  
      <mat-form-field class="example-full-width" style="width: 100%; margin-top: 21px" *ngIf="type === 'LOCAL'">
        <mat-label [class.black]="type === 'LOCAL' && softwarePackage.status === 'Added'">Local description</mat-label>
        <textarea formControlName="localDescription" matInput cdkTextareaAutosize placeholder="Local description" cdkScrollable [cdkAutosizeMaxRows]="5"></textarea>
      </mat-form-field>
    </form>  
  </div>

  <div class="mat-dialog-actions edit-software-form-button-section">

    <div>
      <button mat-raised-button type="button" *ngIf="softwarePackage.status === 'Added' && type === 'GLOBAL'" id="deleteButton" color="warn" (click)="deletePackage()">Delete</button>
      <button mat-raised-button type="button" *ngIf="softwarePackage.status === 'Added'" id="acceptButton" color="primary" (click)="editPackage()">Set</button>
      <button mat-raised-button type="button" *ngIf="softwarePackage.status !== 'Added'" id="okButton" color="primary" mat-dialog-close>Ok</button>
    </div>

    <button mat-stroked-button type="button" *ngIf="softwarePackage.status === 'Added'" id="closeButton" color="primary" mat-dialog-close>Close</button>
  </div>

</div>  
