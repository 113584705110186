import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dl-software-dashboard-title',
  templateUrl: './software-dashboard-title.component.html',
  styleUrls: ['./software-dashboard-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareDashboardTitleComponent {
  @Input() public text = '';
}
