<mat-tab-group one-tab-group>
  <mat-tab label='Local packages'>
    <mat-card-content>
      <dl-file-list *ngIf="localPackages" [data]='localPackages' [viewMode]='"AFFILIATE"' (changeStatusEmitter)="changeStatus($event)" (editPackageEmitter)="editPackage($event)"></dl-file-list>
      <div class="loading-box"><mat-spinner *ngIf="!localPackages"></mat-spinner></div>
    </mat-card-content>
  </mat-tab>
  <mat-tab label='Global packages'>
    <mat-card-content>
      <dl-file-list *ngIf="globalPackages" [data]='globalPackages' [showAffiliatesControls]='true'
                    [viewMode]='"GLOBAL-AFFILIATE"' (createLocalPackageStatusEmitter)="createLocalPackage($event)"></dl-file-list>
                    <div class="loading-box"><mat-spinner *ngIf="!globalPackages"></mat-spinner></div>
    </mat-card-content>
  </mat-tab>
</mat-tab-group>
