import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { PackageType } from 'projects/dialog/src/app/shared/models/core.types';
import { CreateInternalGlobal, GlobalPackage } from 'projects/dialog/src/app/shared/models/global.model';
import { asyncScheduler, of, Subject } from 'rxjs';
import { mergeMap, switchMap, takeUntil, timeout } from 'rxjs/operators';
import { FileUploadService } from '../../../services/file-upload.service';
import { GlobalPackageService } from '../../../services/global-package.service';

@Component({
  selector: 'dl-add-software-modal',
  templateUrl: './add-software-modal.component.html',
  styleUrls: ['./add-software-modal.component.scss']
})
export class AddSoftwareModalComponent implements OnInit, OnDestroy {
  public addPackageGroup: FormGroup;
  public files: File[] = [];
  public packageType: PackageType;
  public fileProcess = 0;
  public inProcess = false;
  private globalPackageDetails: GlobalPackage;
  private destroy$ = new Subject();

  public constructor(private fb: FormBuilder, private globalservice: GlobalPackageService, private oneSnackBarService: OneSnackBarService, private uploadService: FileUploadService, private dialogRef: MatDialogRef<AddSoftwareModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.addPackageGroup = this.fb.group({
      packageName: ['', Validators.required],
      version: ['', Validators.required],
      packageDescription: ['', Validators.required]
    });
  }
  public ngOnInit(): void {
    this.packageType = this.data.packageType;
  }

  /**
   * on file drop handler
   */
  public onFileDropped($event: Array<any>): void {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  public fileBrowseHandler(files: Array<any>): void {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  public deleteFile(index: number): void {
    this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  public prepareFilesList(files: Array<any>): void {
    this.files.push(files[0]);
  }

  /**
   * format bytes
   */
  public formatBytes(bytes: number, decimals?: number): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public addFile(): void {
    this.addPackageGroup.disable();
    this.inProcess = true;
    const createInternalGlobal: CreateInternalGlobal = {
      packageName: this.addPackageGroup.controls.packageName.value,
      packageDescription: this.addPackageGroup.controls.packageDescription.value,
      version: this.addPackageGroup.controls.version.value,
      type: this.packageType,
      instructions: 'instr'
    };
    this.globalservice
      .createPackage(createInternalGlobal)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(globalPackage => {
          return this.globalservice.signUrl(globalPackage.globalPackageId, { fileName: this.files[0].name, fileSize: this.files[0].size }).pipe(takeUntil(this.destroy$));
        }),
        switchMap(globalPackageDetails => {
          this.globalPackageDetails = globalPackageDetails;
          return this.uploadService.uploadFile((globalPackageDetails as GlobalPackage).packageAttachments[0].signedUrl, this.files[0]).pipe(takeUntil(this.destroy$));
        })
      )
      .subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.fileProcess = Math.round(100 * (event.loaded / event.total));
            if (event.loaded === event.total) {
              setTimeout(() => this.dialogRef.close(this.globalPackageDetails), 100);
            }
          }
        },
        error => {
          console.log(error);
          this.addPackageGroup.enable();
          this.inProcess = false;
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
