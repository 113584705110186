import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as addFilesDirectives from './directives';
import * as addFilesComponents from './components';
import * as addFilesModals from './modals';
import { MaterialModule } from '../../material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangeStatusModalComponent } from './modals/change-status-modal/change-status-modal.component';

@NgModule({
  declarations: [addFilesDirectives.allDirectives, addFilesComponents.allComponents, addFilesModals.allModals, ChangeStatusModalComponent],
  exports: [addFilesModals.AddSoftwareModalComponent, addFilesModals.DeployFileModalComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule]
})
export class AddFilesModule {}
