import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dl-file-status',
  templateUrl: './file-status.component.html',
  styleUrls: ['./file-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileStatusComponent {
  @Input() public status: any;
}
