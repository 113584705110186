import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage/local-storage.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Authorized } from '../models/authorized.interface';
import { map } from 'rxjs/operators';

const { clientId } = environment.cognito;

export type LiatUserRole = 'GLOBAL' | 'AFFILIATE' | 'NONE';
export type AD_GROUPS = 'GLOLIATSDDEV_US-Affiliate_Editor' | 'GLOLIATSDDEV_GCS_Member';
export const LOCAL_STORAGE_USER_DATA_KEY = (key: string) => `CognitoIdentityServiceProvider.${clientId}.${key}`;

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  private readonly GROUP_ATTRIBUTE_NAME = 'custom:ADGroups';
  private readonly COUNTRY_ATTRIBUTE_NAME = 'custom:country_code';
  private readonly LAST_AUTH_USER_KEY = 'LastAuthUser';
  private readonly gUrl = environment.apiUrl + '/';
  private currentRoleSubject$: Subject<LiatUserRole> = new Subject<LiatUserRole>();
  public authorized: BehaviorSubject<Authorized> = new BehaviorSubject<Authorized>(null);

  public get currentRole$(): Observable<LiatUserRole> {
    return this.currentRoleSubject$.asObservable();
  }

  public constructor(private localStorageService: LocalStorageService, private http: HttpClient) {}

  public getUserRole(): LiatUserRole {
    const userGroup: AD_GROUPS = this.getUserGroup();
    let currentRole;
    if(userGroup){
      currentRole = userGroup.indexOf('_GCS_') !== -1 ? 'GLOBAL' : 'AFFILIATE';
    } else {
      currentRole = 'NONE';
    }
    this.currentRoleSubject$.next(currentRole);
    return currentRole;
  }

  public getAccessToken(): string {
    return this.getUserAccessToken();
  }

  private getUserGroup(): AD_GROUPS {
    const userGroup = this.findUserAttribute(this.GROUP_ATTRIBUTE_NAME);
    if (userGroup) {
      return userGroup.Value;
    } else {
      return undefined;
    }
  }

  public getCountry(): string {
    return this.authorized.getValue().countries?.[0];
  }

  private findUserAttribute(attribute: string): { Name: string; Value: any } {
      const { UserAttributes } = this.getUserDataFromLocalStorage();
      const userAttributes = UserAttributes.find(el => el.Name.toUpperCase() === attribute.toUpperCase());
      return userAttributes;
  }

  public getLoggedUserName(): string {
    return localStorage.getItem(LOCAL_STORAGE_USER_DATA_KEY(this.LAST_AUTH_USER_KEY));
  }

  private getUserDataFromLocalStorage(): any {
    return this.localStorageService.getUserData(LOCAL_STORAGE_USER_DATA_KEY(`${this.getLoggedUserName()}.userData`));
  }

  private getUserAccessToken(): any {
    return localStorage.getItem(LOCAL_STORAGE_USER_DATA_KEY(`${this.getLoggedUserName()}.accessToken`));
  }

  private createHeader(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${this.getAccessToken()}`);
    return headers;
  }

  public getAuthorized(): Observable<Authorized> {
    const url = `${this.gUrl}v1/internal/profile`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.get<any>(url, { headers }).pipe(map(authorized => {this.authorized.next(authorized.profile)
    return authorized.profile as Authorized}));
  }

}
