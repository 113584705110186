import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OneTopbarModule } from '@one/angular-kit/navigation';
import { OneListModule } from '@one/angular-kit/layout';
import { DoubleGlobalAreaComponent } from './double-global-area/double-global-area.component';
import { FooterModule } from '../footer/footer.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { OneSnackBarModule } from '@one/angular-kit/modal';
import { OneIconModule } from '@one/angular-kit/icon';
import { MatDividerModule } from '@angular/material/divider';
import { OneBadgeModule } from '@one/angular-kit/indicator';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [DoubleGlobalAreaComponent],
  exports: [DoubleGlobalAreaComponent],
  imports: [MatTabsModule, FooterModule, CommonModule, MatButtonModule, MatDialogModule, MatIconModule, MatMenuModule, MatListModule, MatToolbarModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, OneListModule, OneTopbarModule, RouterModule, MatSidenavModule, MatTooltipModule, ReactiveFormsModule, MatRadioModule, OneSnackBarModule, OneIconModule, MatDividerModule, OneBadgeModule],
  entryComponents: []
})
export class HeaderModule {}
