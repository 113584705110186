import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { catchError, delay, retry } from 'rxjs/operators';
import { AppInitializerService } from '../../core/app-initializer/app-initializer.service';

@Injectable()
export class SoftwareDownloadErrorHandlingInterceptor implements HttpInterceptor {

  constructor(private snackBarService:OneSnackBarService, private appInitializerService:AppInitializerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(

      catchError((err:HttpErrorResponse )=> {
        if(request.url.includes('/internal/package')){
          if(err.status === 400 || err.status === 409){
            console.log(err.error.message)
            this.snackBarService.open(err.error.message, OneSnackBarType.ERROR)
          }
          if((err.status === 401 || err.status === 403)){
            this.snackBarService.open('Not enough permissions', OneSnackBarType.ERROR);
            console.log(err.error.message)
          }
          if((err.status === 500)){
            this.snackBarService.open('Something went wrong.', OneSnackBarType.ERROR);
            console.log(err.error.message)
          }
        }
        return throwError(err);
      })
    );
  }
}
