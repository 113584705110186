import { Inject, Injectable } from '@angular/core';
import { UserProfile } from './models/user-profile.model';
import { Subject } from 'rxjs';
import { ENVIRONMENT } from '../models/environment.token';
import { EnvironmentInterface } from '../models/environment.interface';
import { Amplify, Auth } from 'aws-amplify';
@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private readonly signOutSubject = new Subject<boolean>();
  public signOut$ = this.signOutSubject.asObservable();

  public constructor(@Inject(ENVIRONMENT) private environment: EnvironmentInterface) 
  { }

  public init(): Promise<UserProfile | null> {
    return new Promise<UserProfile>((resolve, reject) => {
      
      this.configureAmplify();

      // Coming from login
      const isComingFromLogin = window.location.pathname.endsWith('/login') && 
        window.location.search.includes('?code=');
      if (isComingFromLogin) {
        this.getCurrentUser()
          .then((userProfile) => resolve(userProfile))
          .catch(reject);
        return;
      }

      // Coming from logout
      const isComingFromLogout = window.location.pathname.endsWith('/logout');
      if (isComingFromLogout) {
        window.location.href = window.location.origin;
        return;
      }

      // Check user state
      this.getCurrentUser()
        .then((userProfile) => {
          // If logged in cognito then return user
          if (Boolean(userProfile)) {
            resolve(userProfile);
          } else {
            this.signIn();
          }
        })
        .catch(resolve);
    });
  }

  public async getCurrentUser(): Promise<UserProfile | null> {
    try {
      const amplifyUser = await Auth.currentAuthenticatedUser();
      if (amplifyUser !== null) {
        return (this.formatUserProfile(amplifyUser));
      }
    } catch {
      // Not authenticated
    }
    return null;
  }

  public async signIn(): Promise<void> {
    const loginOptions = {
      customProvider: this.environment.cognito.idpName,
    };
    await Auth.federatedSignIn(loginOptions);
  }

  public async signOut(): Promise<void> {
    await Auth.signOut();
  }

  private configureAmplify(): void {
    const oauth = {
      clientId: this.environment.cognito.clientId,
      domain: this.environment.cognito.domain,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: `${window.location.origin}/login`,
      redirectSignOut: `${window.location.origin}/logout`,
      responseType: 'code',
    };

    const amplifyConfig = {
      aws_project_region: 'eu-central-1',
      aws_cognito_region: 'eu-central-1',
      aws_user_pools_id: this.environment.cognito.userPoolId,
      aws_user_pools_web_client_id: this.environment.cognito.clientId,
      oauth,
    };

    Amplify.configure(amplifyConfig);
  }

  private formatUserProfile(amplifyUser: any): UserProfile {
    const signInUser: any = amplifyUser.signInUserSession;
    const signInUserPayload = signInUser.idToken.payload;
    const name = this.extracNameFromIdp(amplifyUser);

    return {
      sub: signInUserPayload.sub,
      name: name,
      givenName: signInUserPayload?.given_name,
      familyName: signInUserPayload?.family_name,
      email: signInUserPayload.email,
      jwtId: signInUser.idToken.jwtToken,
      jwtAccess: signInUser.accessToken.jwtToken
    } as UserProfile;
  }

  private extracNameFromIdp(amplifyUser): string {
    try
    {
      if (this.environment.cognito.idpName) {
        return JSON.parse(amplifyUser.attributes.identities)[0].userId;
      }
      else {
        return amplifyUser.attributes.name;
      }
    }
    catch {}
    return null;
  }
}
