<div class='add-software-form-modal'>

  <h2 matDialogTitle>Add global package</h2>

  <div class="add-package-form">
    <form [formGroup]='addPackageGroup'>

      <div class="form-layout">
        
        <div class="file-upload-and-list-container">
  
          <div *ngIf="files.length === 0" class='container' dlDnd (fileDropped)='onFileDropped($event)'>
            <input type='file' #fileDropRef id='fileDropRef' multiple (change)='fileBrowseHandler($event.target.files)' />
            <div class="upload-file-icon">
              <img src="assets/file-upload-drag-n-drop.svg" alt="File Upload Icon">
            </div>
            <div class="file-upload-text">
              <h4>Drag and drop file here</h4>
              <h4>or</h4>
              <label for='fileDropRef'>Browse for file</label>
            </div>
          </div>
      
          <div *ngIf="files.length > 0" class='files-list'>
            <div class='single-file' *ngFor='let file of files; let i = index'>
              <div class='file-icon' style='width: 50px'>
                <img src="assets/file-icon.svg" alt="File Icon">
              </div>
              <div class='info'>
                <h4 class='name'>
                  {{ file?.name }}
                </h4>
                <p class='size'>
                  {{ formatBytes(file?.size) }}
                </p>
                <dl-progress *ngIf="inProcess" [progress]='fileProcess'></dl-progress>
              </div>
              <div class='delete' (click)='deleteFile(i)' *ngIf="!inProcess">
                <img src="assets/delete-icon.svg" alt="File Icon">
              </div>
            </div>
      
          </div>
  
        </div>
  
        <div class="form-filed-group">
          <mat-form-field class='form-field' style="margin-top: 15px;">
            <mat-label class="black">Package Name</mat-label>
            <input formControlName='packageName' matInput placeholder='Package name'>
          </mat-form-field>
      
          <mat-form-field class='form-field'>
            <mat-label class="black">Global Description</mat-label>
            <textarea formControlName='packageDescription' matInput cdkTextareaAutosize placeholder='Description' cdkScrollable [cdkAutosizeMaxRows]="5"></textarea>
          </mat-form-field>
      
          <mat-form-field class='form-field'>
            <mat-label class="black">Version</mat-label>
            <input  formControlName='version' matInput placeholder='Version'>
          </mat-form-field>
        </div>
    
      </div>
  
    </form>  
  </div>

  <div class='mat-dialog-actions add-package-buttons'>
    <button mat-stroked-button type='button' id='closeButton' color='primary' mat-dialog-close [disabled]="inProcess">Close</button>
    <button mat-raised-button type='button' [disabled]="!addPackageGroup.valid || files.length === 0 || inProcess" id='acceptButton' color='primary' (click)='addFile()'>Add
      package
    </button>
  </div>

</div>
