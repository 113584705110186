import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalPackage } from 'projects/dialog/src/app/shared/models/global.model';
import { PackageStatusStateMachine } from 'projects/dialog/src/app/shared/models/core.types';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';

export type ViewModes = 'GLOBAL' | 'AFFILIATE' | 'GLOBAL-AFFILIATE';

@Component({
  selector: 'dl-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss']
})
export class FileListComponent implements AfterViewInit {
  public displayedColumns: string[] = [];
  public dataSource: MatTableDataSource<GlobalPackage[]>;
  public currentViewMode: ViewModes;
  public statusList: { label: string; value: any }[];
  public activeStatusList: { label: string; value: any }[];
  public statusFormControl = new FormControl();

  @ViewChild(MatSort) sort: MatSort;

  @Input() public showGlobalControls = false;
  @Input() public showAffiliatesControls = false;

  @Input() public set data(data: any[]) {
    this.dataSource = new MatTableDataSource<any[]>(data);
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = ((data, filter) => {
      return !filter.status || filter.status.includes(data.status);
    }) as (PeriodicElement, string) => boolean;
    this.filterTable(this.activeStatusList);
  }

  @Input() public set viewMode(viewMode: ViewModes) {
    this.currentViewMode = viewMode;
    if (viewMode === 'GLOBAL') {
      this.displayedColumns = ['packageName', 'packageDescription', 'status', 'edit', 'actions'];
      this.statusList = [
        { label: 'Added', value: 'Added' },
        { label: 'Deployed', value: 'Deployed' },
        { label: 'Published', value: 'Published' },
        { label: 'Unpublished', value: 'Unpublished' }
      ];
      this.activeStatusList = [
        { label: 'Added', value: 'Added' },
        { label: 'Deployed', value: 'Deployed' },
        { label: 'Published', value: 'Published' }
      ];
      this.statusFormControl.setValue(this.activeStatusList);
      this.filterTable(this.activeStatusList);
    }

    if (viewMode === 'AFFILIATE') {
      this.displayedColumns = ['packageName', 'packageDescription', 'localPackageDescription', 'status', 'edit', 'actions'];
      this.statusList = [
        { label: 'Added', value: 'Added' },
        { label: 'Deployed', value: 'Deployed' },
        { label: 'Unpublished', value: 'Unpublished' }
      ];
      this.activeStatusList = [
        { label: 'Added', value: 'Added' },
        { label: 'Deployed', value: 'Deployed' }
      ];
      this.statusFormControl.setValue(this.activeStatusList);
      this.filterTable(this.activeStatusList);
    }
    if (viewMode === 'GLOBAL-AFFILIATE') {
      this.displayedColumns = ['packageName', 'packageDescription', 'actions'];
    }
  }

  @Output()
  public editPackageEmitter: EventEmitter<GlobalPackage> = new EventEmitter<GlobalPackage>();

  @Output()
  public changeStatusEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public createLocalPackageStatusEmitter: EventEmitter<GlobalPackage> = new EventEmitter<GlobalPackage>();

  @Output()
  public addSoftwareEmitter: EventEmitter<any> = new EventEmitter<any>();

  public constructor() {}

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    if (this.currentViewMode !== 'GLOBAL-AFFILIATE') {
      this.statusFormControl.valueChanges.subscribe(value => {
        this.filterTable(value);
      });
    }
  }

  public changeStatus(editedPackage: GlobalPackage, newStatus: PackageStatusStateMachine): void {
    this.changeStatusEmitter.emit({ editedPackage, newStatus });
  }

  public createLocalPackage(globalPackage: GlobalPackage): void {
    this.createLocalPackageStatusEmitter.emit(globalPackage);
  }

  public editPackage(editedPackage: GlobalPackage): void {
    this.editPackageEmitter.emit(editedPackage);
  }

  public addSoftware() {
    this.addSoftwareEmitter.emit();
  }

  private filterTable(value: { label: string; value: any }[]) {
    if(value){
    const filter = ({ status: (value as { label: string; value: any }[]).map(el => el.value) } as unknown) as string;
    this.dataSource.filter = filter;
    }

  }
}
