import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fileStatusesComponents from './components';
import { MatChipsModule } from '@angular/material/chips';
import { OneChipModule } from '@one/angular-kit/indicator';

@NgModule({
  declarations: [...fileStatusesComponents.allComponents],
  exports: [fileStatusesComponents.FileStatusComponent],
  imports: [CommonModule, MatChipsModule, OneChipModule]
})
export class FileStatusesModule {}
