import { Inject, Injectable } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DetermineBundleService {
  public readonly isDefaultBundle = this.baseHref === '/';
  public constructor(@Inject(APP_BASE_HREF) private baseHref: string) {}
}
