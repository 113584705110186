import { SoftwareDashboardComponent } from './dashboards/software-dashboard/software-dashboard.component';
import { SoftwareDashboardTitleComponent } from './software-dashboard-title/software-dashboard-title.component';
import { GlobalUserDashboardComponent } from './global-user-dashboard/global-user-dashboard.component';
import { AffiliateUserDashboardComponent } from './affiliate-user-dashboard/affiliate-user-dashboard.component';
import { ScriptDashboardComponent } from './dashboards/script-dashboard/script-dashboard.component';

export const allComponents: any[] = [SoftwareDashboardComponent, SoftwareDashboardTitleComponent, GlobalUserDashboardComponent, AffiliateUserDashboardComponent, ScriptDashboardComponent];

export * from './dashboards/software-dashboard/software-dashboard.component';
export * from './software-dashboard-title/software-dashboard-title.component';
export * from './global-user-dashboard/global-user-dashboard.component';
export * from './affiliate-user-dashboard/affiliate-user-dashboard.component';
export * from './dashboards/script-dashboard/script-dashboard.component';
