<div class="modal-container">
  <h2 matDialogTitle>Change package status</h2>  
  <p *ngIf="status === 'Published'">Are you sure you want to publish <b>{{editedPackage.packageName}}</b> package globally?</p>
  <p *ngIf="status === 'Unpublished'">Are you sure you want to unpublish <b>{{editedPackage.packageName}}</b> package globally?<p>
  <p *ngIf="status === 'Added'">Are you sure you want to undeploy <b>{{editedPackage.packageName}}</b> package from the customer view?</p>
  <p *ngIf="status === 'Deployed'">Are you sure you want to deploy <b>{{editedPackage.packageName}}</b> package to the customer view?</p>
  <div class='mat-dialog-actions' style='justify-content: space-between'>
    <button mat-stroked-button type="button" id="closeButton" color="primary" mat-dialog-close>Close</button>
    <button mat-raised-button type="button" id="acceptButton" color="primary" [mat-dialog-close]="true">Ok</button>
  </div>
</div>
