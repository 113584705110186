import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { OneChipModule } from "@one/angular-kit/indicator";
import { MaterialModule } from "../../material/material.module";
import { TruncatePipe } from "./pipes/truncate.pipe";

@NgModule({
  declarations: [TruncatePipe],
  exports: [TruncatePipe],
  imports: [CommonModule, MaterialModule, OneChipModule],
  providers: [MatTooltipModule]
})
export class SharedModule {}
