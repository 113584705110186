import * as XLSX from 'xlsx';

export class AppUtils {

    constructor() { }

    public mimeType = 'application/pdf';

    exportToCSV(data, filename, headerList) {
        let csvData = this.convertToCSV(data, headerList);
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let dwldLink = document.createElement('a');
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {
            //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', filename + '.csv');
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    public convertToCSV(objArray, headerList) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = 'S.No,';

        for (let index in headerList) {
            row += headerList[index].key + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = i + 1 + '';
            for (let index in headerList) {
                let head = headerList[index].value;
                let value = array[i][head] || '';
                line += ',' + value;
            }
            str += line + '\r\n';
        }
        return str;
    }

    exportToExcel(arr: any[], name?: string) {
        let { sheetName, fileName } = this.getFileName(name);
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(arr);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }

    getFileName = (name: string) => {
        let timeSpan = new Date().toISOString();
        let sheetName = name || 'ExportResult';
        let fileName = `${sheetName}-${timeSpan}`;
        return {
            sheetName,
            fileName
        };
    };

    fetchDoc(apiURL, apikey?, accessToken?, fileName?) {
        let options = {
            method: 'GET',
            headers: {}
        };
        if (accessToken) {
            options = {
                method: 'GET',
                headers: {
                    'x-api-key': apikey,
                    'Authorization': accessToken
                }
            };
        }
        let isDownloadable = false;
        if (fileName) {
            this.mimeType = this.getMimeType(fileName);
            const ext = fileName ? fileName.split('.').pop() : '';
            const downloadableFile = ['csv', 'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', 'zip', 'tar', 'rar', 'gzip', 'txt'];
            isDownloadable = (downloadableFile.indexOf(ext) >= 0);
        }
        let self = this;
        fetch(apiURL, options)
            .then(handleErrors)
            .then(r => r.blob())
            .then((blob) => {
                // this.showFile
                console.log("+++++++++++++++++++++++++++++++++++++=")
                let newBlob = new Blob([blob], { type: self.mimeType }); //'application/pdf'
                const data = window.URL.createObjectURL(newBlob);
                //window.open(data, '_blank')
                var link = document.createElement('a');
                console.log("linklinklinklink : ",link);
                link.href = data;
                link.target = '_blank';
                if (isDownloadable) {
                    link.download = fileName;
                }
                link.click();
                setTimeout(function () {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                }, 100);
            })
            .catch(error => console.log('ERROR: ' + error));

        function handleErrors(response) {
            if (!response.ok) {
                console.log("Response id not okkkkk");
                throw Error(response.statusText);
            }
            console.log("Response id very okkkkk");
            return response;
        }
    }

    public showFile(blob) {
        var newBlob = new Blob([blob], { type: blob.type }); //'application/pdf'
        const data = window.URL.createObjectURL(newBlob);
        window.open(data, '_blank');
    }

    formatFilesIcons(filename: string) {
        const ext = filename ? filename.split('.').pop() : '';
        let fileIcon = 'file_pdf';
        switch (ext) {
            case 'apng':
            case 'bmp':
            case 'gif':
            case 'pjpeg':
            case 'png':
            case 'jpeg':
            case 'jpg':
            case 'PNG':
                fileIcon = 'image';
                break;
            case 'csv':
            case 'xls':
            case 'xlsx':
                fileIcon = 'file_excel';
                break;
            case 'doc':
            case 'docx':
                fileIcon = 'file_word';
                break;
            case 'pdf':
                fileIcon = 'file_pdf';
                break;
            case 'ppt':
            case 'pptx':
                fileIcon = 'document';
                break;
            case 'zip':
            case 'tar':
            case 'rar':
            case 'gzip':
                fileIcon = 'file_folder';
                break;
            case 'txt':
                fileIcon = 'document'; // icon is not available , temp document used
                break;
            default:
                fileIcon = 'document'; // icon is not available , temp document used
                break;
        }
        return fileIcon;
    }

    getMimeType(fileName: string) {
        let tokens = fileName ? fileName.split('.').pop() : '';
        let fileExtension = '.' + tokens.toLowerCase();
        switch (fileExtension) {
            case ('.aac'):
                return ('audio/aac');
            case ('.abw'):
                return ('application/x-abiword');
            case ('.arc'):
                return ('application/x-freearc');
            case ('.avi'):
                return ('video/x-msvideo');
            case ('.azw'):
                return ('application/vnd.amazon.ebook');
            case ('.bin'):
                return ('application/octet-stream');
            case ('.bmp'):
                return ('image/bmp');
            case ('.bz'):
                return ('application/x-bzip');
            case ('.bz2'):
                return ('application/x-bzip2');
            case ('.cda'):
                return ('application/x-cdf');
            case ('.csh'):
                return ('application/x-csh');
            case ('.css'):
                return ('text/css');
            case ('.csv'):
                return ('text/csv');
            case ('.doc'):
                return ('application/msword');
            case ('.docx'):
                return ('application/vnd.openxmlformats-officedocument.wordprocessingml.document');
            case ('.eot'):
                return ('application/vnd.ms-fontobject');
            case ('.epub'):
                return ('application/epub+zip');
            case ('.gz'):
                return ('application/gzip');
            case ('.gif'):
                return ('image/gif');
            case ('.htm'):
                return ('text/html');
            case ('.ico'):
                return ('image/vnd.microsoft.icon');
            case ('.ics'):
                return ('text/calendar');
            case ('.jar'):
                return ('application/java-archive');
            case ('.jpeg'):
                return ('image/jpeg');
            case ('.js'):
                return ('text/javascript');
            case ('.json'):
                return ('application/json');
            case ('.jsonld'):
                return ('application/ld+json');
            case ('.mid'):
                return ('audio/midi audio/x-midi');
            case ('.midi'):
                return ('audio/midi audio/x-midi');
            case ('.mjs'):
                return ('text/javascript');
            case ('.mp3'):
                return ('audio/mpeg');
            case ('.mp4'):
                return ('video/mp4');
            case ('.mpeg'):
                return ('video/mpeg');
            case ('.mpkg'):
                return ('application/vnd.apple.installer+xml');
            case ('.odp'):
                return ('application/vnd.oasis.opendocument.presentation');
            case ('.ods'):
                return ('application/vnd.oasis.opendocument.spreadsheet');
            case ('.odt'):
                return ('application/vnd.oasis.opendocument.text');
            case ('.oga'):
                return ('audio/ogg');
            case ('.ogv'):
                return ('video/ogg');
            case ('.ogx'):
                return ('application/ogg');
            case ('.opus'):
                return ('audio/opus');
            case ('.otf'):
                return ('font/otf');
            case ('.png'):
                return ('image/png');
            case ('.pdf'):
                return ('application/pdf');
            case ('.php'):
                return ('application/x-httpd-php');
            case ('.ppt'):
                return ('application/vnd.ms-powerpoint');
            case ('.pptx'):
                return ('application/vnd.openxmlformats-officedocument.presentationml.presentation');
            case ('.rar'):
                return ('application/vnd.rar');
            case ('.rtf'):
                return ('application/rtf');
            case ('.sh'):
                return ('application/x-sh');
            case ('.svg'):
                return ('image/svg+xml');
            case ('.swf'):
                return ('application/x-shockwave-flash');
            case ('.tar'):
                return ('application/x-tar');
            case ('.tif'):
                return ('image/tiff');
            case ('.tiff'):
                return ('image/tiff');
            case ('.ts'):
                return ('video/mp2t');
            case ('.ttf'):
                return ('font/ttf');
            case ('.txt'):
                return ('text/plain');
            case ('.vsd'):
                return ('application/vnd.visio');
            case ('.wav'):
                return ('audio/wav');
            case ('.weba'):
                return ('audio/webm');
            case ('.webm'):
                return ('video/webm');
            case ('.webp'):
                return ('image/webp');
            case ('.woff'):
                return ('font/woff');
            case ('.woff2'):
                return ('font/woff2');
            case ('.xhtml'):
                return ('application/xhtml+xml');
            case ('.xls'):
                return ('application/vnd.ms-excel');
            case ('.xlsx'):
                return ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            case ('.xml'):
                return ('application/xml');
            case ('.xul'):
                return ('application/vnd.mozilla.xul+xml');
            case ('.zip'):
                return ('application/zip');
            case ('.3gp'):
                return ('video/3gpp');
            case ('.3g2'):
                return ('video/3gpp2');
            case ('.7z'):
                return ('application/x-7z-compressed');
            default:
                return 'application/octet-stream';
        }
    }

    getLowerCase(data) {
        let lowercaseData = (data) ? data.toString().toLowerCase() : '';
        return lowercaseData;
    }

    /**
     * 
     * Input : "<p>My document body</p><p>where is output</p>"
     * output : My document bodywhere is output
     */
    extractContent(html) {
        return new DOMParser().parseFromString(html, "text/html").
            documentElement.textContent;
    }

    // format date to dd-mmm-yyyy (07-Jan-2021)
    public formatDateOnly(dateValue: Date): string {
        if (dateValue) {
            const date = new Date(dateValue);
            const day = date.toLocaleString('default', { day: '2-digit' });
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.toLocaleString('default', { year: 'numeric' });
            return day + '-' + month + '-' + year;
        } else {
            return '';
        }
    }

    formatDateWithTime(dateValue: Date) {
        if (dateValue) {
            let localDate = new Date(dateValue);
            // let localDate = date.toLocaleString('default', { hourCycle:'h24' });
            const day = localDate.toLocaleString('default', { day: '2-digit' });
            const month = localDate.toLocaleString('default', { month: 'short' });
            const year = localDate.toLocaleString('default', { year: 'numeric' });
            const hour = localDate.toLocaleString('default', { hour12: false, hour: '2-digit' });
            let minute = localDate.toLocaleString('default', { hour12: false, minute: '2-digit' });
            minute = (parseInt(minute) < 10) ? `0${minute}` : minute;
            return day + '-' + month + '-' + year + ' ' + hour + ':' + minute;
        } else {
            return '';
        }
    }
}
