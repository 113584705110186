import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fileListComponents from './components';
import { OneTableModule } from '@one/angular-kit/data-table';
import { MaterialModule } from '../../material/material.module';
import { OneChipModule } from '@one/angular-kit/indicator';
import { FileStatusesModule } from '../file-statuses/file-statuses.module';
import { OneTooltipModule } from '@one/angular-kit/modal';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OneFilteringChipModule } from '@one/angular-kit/indicator';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [...fileListComponents.allComponents],
  exports: [fileListComponents.FileListComponent],
  imports: [CommonModule, OneTableModule, MaterialModule, OneChipModule, FileStatusesModule, OneTooltipModule, OneFilteringChipModule, ReactiveFormsModule,SharedModule],
  providers: [MatTooltipModule]
})
export class FileListModule {}
