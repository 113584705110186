import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { GlobalPackageService } from '../../../services/global-package.service';
import { LocalPackageService } from '../../../services/local-package.service';
import { GlobalPackage, UpdateInternalGlobal } from 'projects/dialog/src/app/shared/models/global.model';
import { LocalPackage, UpdateInternalLocal } from 'projects/dialog/src/app/shared/models/local.model';
import { ModalService } from 'projects/dialog/src/app/core/services/modal.service';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';

@Component({
  selector: 'dl-edit-software-modal',
  templateUrl: './edit-software-modal.component.html',
  styleUrls: ['./edit-software-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class EditSoftwareModalComponent implements OnInit {
  public packageInformationForm: FormGroup;
  public type: string = '';
  public softwarePackage: GlobalPackage;
  public downloadUrl: string = '';
  public files: any[] = [];

  public constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<EditSoftwareModalComponent>, private localService: LocalPackageService, private globalService: GlobalPackageService, private oneSnackBarService: OneSnackBarService, private modalService: ModalService, private globalPackageService: GlobalPackageService) {}

  public ngOnInit(): void {
    this.type = this.data.type;
    if (this.type === 'GLOBAL') {
      this.globalService.getPackageDetails(this.data.package.globalPackageId).subscribe(globalPackage => {
        this.softwarePackage = globalPackage;
        this.packageInformationForm = this.fb.group({
          packageName: [{ value: this.softwarePackage.packageName, disabled: this.softwarePackage.status !== 'Added' }, Validators.required],
          description: [{ value: this.softwarePackage.packageDescription, disabled: this.softwarePackage.status !== 'Added' }, Validators.required],
          version: [{ value: this.softwarePackage.version, disabled: this.softwarePackage.status !== 'Added' }, Validators.required],
          status: [{ value: this.softwarePackage.status, disabled: true }],
          type: [{ value: this.softwarePackage.type, disabled: true }]
        });
        if (this.softwarePackage.packageAttachments?.length > 0) {
          this.getDownloadUrl(this.softwarePackage.globalPackageId, this.softwarePackage.packageAttachments[0].fileName);
        }
      });
    } else {
      this.localService.getPackageDetails(this.data.package.localPackageId).subscribe(localPackage => {
        this.softwarePackage = localPackage;
        console.log(localPackage);
        this.packageInformationForm = this.fb.group({
          packageName: [{ value: this.softwarePackage.packageName, disabled: true }, Validators.required],
          description: [{ value: this.softwarePackage.packageDescription, disabled: true }, Validators.required],
          version: [{ value: this.softwarePackage.version, disabled: true }, Validators.required],
          localDescription: [{ value: (this.softwarePackage as LocalPackage).localPackageDescription, disabled: this.softwarePackage.status !== 'Added' }, Validators.required],
          status: [{ value: this.softwarePackage.status, disabled: true }],
          type: [{ value: this.softwarePackage.type, disabled: true }]
        });
        if (this.softwarePackage.packageAttachments?.length > 0) {
          this.getDownloadUrl(this.softwarePackage.globalPackageId, this.softwarePackage.packageAttachments[0].fileName);
        }
      });
    }
  }

  public getDownloadUrl(globalPackageId: string, filename: string): void {
    this.globalService.downloadPackage(this.softwarePackage.globalPackageId, this.softwarePackage.packageAttachments[0].fileName).subscribe(response => {
      const urlStr: string = response.body.toString();
      if (urlStr.startsWith('https:')) {
        this.downloadUrl = urlStr;
      } else {
        console.error('There is a problem with providing a file');
      }
    });
  }

  public deletePackage() {
    const modalRef = this.modalService.showModal<DeleteConfirmationComponent, any, any>(DeleteConfirmationComponent, { editedPackage: this.softwarePackage });
    modalRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      } else {
        this.globalPackageService.deletePackage(this.softwarePackage.globalPackageId).subscribe(() => {
          this.dialogRef.close(this.softwarePackage);
        });
      }
    });
  }

  /**
   * format bytes
   */
  public formatBytes(bytes: number, decimals?: number): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public editPackage(): void {
    if (this.type === 'GLOBAL') {
      this.editGlobalPackage();
    } else {
      this.editLocalPackage();
    }
  }

  private editLocalPackage(): void {
    const updateInternalLocal: UpdateInternalLocal = {
      localPackageName: this.softwarePackage.packageName,
      localPackageDescription: this.packageInformationForm.controls.localDescription.value,
      localInstructions: 'instr'
    };
    this.localService.updatePackage((this.softwarePackage as LocalPackage).localPackageId, updateInternalLocal).subscribe(result => {
      this.dialogRef.close(result);
    });
  }

  private editGlobalPackage(): void {
    const updateInternalGlobal: UpdateInternalGlobal = {
      packageName : this.packageInformationForm.controls.packageName.value,
      packageDescription: this.packageInformationForm.controls.description.value,
      version: this.packageInformationForm.controls.version.value,
      type: this.softwarePackage.type,
      instructions: 'instr',
      packageAttachments: this.softwarePackage.packageAttachments
    };

    this.globalService.updatePackage(this.softwarePackage.globalPackageId, updateInternalGlobal).subscribe(globalPackage => {
      this.dialogRef.close(globalPackage);
    });
  }
}
