import { HttpClient, HttpHeaders, HttpParams, HttpSentEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/dialog/src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserDataService } from '../../core/services/user-data.service';
import { PackageAttachment, PackageStatusStateMachine } from '../../shared/models/core.types';
import { CreateInternalGlobal, GlobalPackage, UpdateInternalGlobal } from '../../shared/models/global.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalPackageService {
  private readonly gUrl = environment.apiUrl + '/';

  public globalPackages: BehaviorSubject<GlobalPackage[]> = new BehaviorSubject(undefined);

  getGlobalPackage() {
    return this.globalPackages.asObservable();
  }

  getGlobalPackageValue() {
    return this.globalPackages.getValue();
  }

  setGlobalPackage(packageData) {
    this.globalPackages.next(packageData);
  }

  public refreshGlobalPackages(): void {
    this.getPackageList().subscribe(globalPackages => this.globalPackages.next(globalPackages));
  }

  public constructor(private http: HttpClient, private userDataService: UserDataService) {}

  public createPackage(createInternalGlobal: CreateInternalGlobal): Observable<GlobalPackage> {
    const url = `${this.gUrl}v1/internal/package/global`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.post<GlobalPackage>(url, createInternalGlobal, { headers });
  }

  public uploadPackage(globalPackageId: string, file: File): Observable<any> {
    const url = `${this.gUrl}v1/internal/package/global/${globalPackageId}/file`;
    const headers: HttpHeaders = this.createHeader();
    const data = new FormData();
    data.append('file', file);
    return this.http.post(url, data, { headers, reportProgress: true, observe: 'events' });
  }

  public updatePackage(globalPackageId: string, updateInternalGlobal: UpdateInternalGlobal): Observable<GlobalPackage> {
    const url = `${this.gUrl}v1/internal/package/global/${globalPackageId}`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.put<GlobalPackage>(url, updateInternalGlobal, { headers });
  }

  public changeStatus(globalPackageId: string, status: PackageStatusStateMachine): Observable<GlobalPackage> {
    const url = `${this.gUrl}v1/internal/package/global/${globalPackageId}`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.patch<GlobalPackage>(url, { status }, { headers });
  }

  public getPackageList(): Observable<GlobalPackage[]> {
    const url = `${this.gUrl}v1/internal/package/global`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.get<GlobalPackage[]>(url, { headers });
  }

  public getPackageDetails(globalPackageId: string): Observable<GlobalPackage> {
    const url = `${this.gUrl}v1/internal/package/global/${globalPackageId}`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.get<GlobalPackage>(url, { headers });
  }

  public signUrl(globalPackageId: string, attachment: PackageAttachment): Observable<GlobalPackage> {
    const url = `${this.gUrl}v1/internal/package/global/${globalPackageId}/signUrl`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.post<GlobalPackage>(url, attachment, { headers });
  }

  public downloadPackage(globalPackageId: string, filename: string): Observable<Response> {
    const url = `${this.gUrl}v1/internal/package/global/${globalPackageId}/file`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.get<Response>(url, { headers, params: { filename } });
  }

  public deletePackage(globalPackageId: string): Observable<Response> {
    const url = `${this.gUrl}v1/internal/package/global/${globalPackageId}`;
    const headers: HttpHeaders = this.createHeader();
    return this.http.delete<Response>(url, { headers });
  }

  private createHeader(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${this.userDataService.getAccessToken()}`);
    return headers;
  }
}
