import { Injectable } from '@angular/core';

/*
https://developers.google.com/web/updates/2020/07/referrer-policy-new-chrome-default
Chrome did change its default Referrer Policy. When a website does not set its own Referrer Policy,
Chrome uses strict-origin-when-cross-origin. strict-origin-when-cross-origin means that if website A
opens website B and website B accesses document.referrer, website B does not get the full path of website A
when the website are cross-origin https://web.dev/same-site-same-origin/. In our case dialog opens the login
website, which is considered cross-origin, because it has a different subdomain. The login page wants to read
country and language from the path of the url /dialog/fr/fr, but document.referrer only gives the url
https://dialogportal.roche.com and hides the /dialog/fr/fr. In order for the login website to read the full path
we need to explicitly set a referrer policy. There are 3 ways of doing it: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Referrer-Policy
- HTTP Header
- <meta> HTML tag
- <a> setting it on each link individually

I decided to set it on each link individually to allow the setting of a more strict Referrer Policy for all
other links with an HTTP Header or meta tag.
 */
@Injectable({
  providedIn: 'root'
})
export class OpenLinkWithReferrerService {
  public openLink(href: string, openInNewTab?: boolean): void {
    const a = document.createElement('a');
    a.href = href;
    a.setAttribute('referrerpolicy', 'no-referrer-when-downgrade');
    if (openInNewTab) {
      a.setAttribute('target', '_blank');
    }
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
  }
}
