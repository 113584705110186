<div class="files-list-overview-table">
  <one-table>
    <one-table-header>
      <div one-table-title>
        Package list
      </div>
      <div one-table-center-content></div>
      <div one-action-buttons>
        <button *ngIf="currentViewMode === 'GLOBAL'" mat-raised-button  class="add-button" color="primary" id="submitButton" (click)="addSoftware()">Add package</button>
        <one-filtering-chip *ngIf="currentViewMode !=='GLOBAL-AFFILIATE'" [list]="statusList" [formControl]="statusFormControl" [placeholder]="'Pick status'" [width]="'160px'" label="Status"> </one-filtering-chip>
      </div>
    </one-table-header>
    <one-table-content>
      <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows matSortDirection="desc">
        <ng-container matColumnDef="packageName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Package Name</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="truncate-tex">
              <span class="package-name">{{ element.packageName }} </span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="packageDescription">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Global Description</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="truncate-text description-cell">{{element.packageDescription.length > 140? (element.packageDescription | truncatePipe : 120 : true ): element.packageDescription}}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="localPackageDescription" *ngIf="currentViewMode === 'AFFILIATE'">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Local description</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="truncate-text description-cell"
             matTooltip="{{element.localPackageDescription.length > 140? element.localPackageDescription: ''}}">
             {{element.localPackageDescription.length > 140? (element.localPackageDescription | truncatePipe : 120 : true ): element.localPackageDescription}}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status" *ngIf="currentViewMode !== 'GLOBAL-AFFILIATE'">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <dl-file-status [status]="element.status"></dl-file-status>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="button-row" *ngIf="showGlobalControls">
              <div class="button-box">
                <button *ngIf="element.status === 'Added'; else unpublish" (click)="changeStatus(element, 'Published')" color="primary" mat-raised-button>
                  Publish
                </button>
                <button *ngIf="element.status === 'Published' || element.status === 'Deployed'" color="primary" mat-raised-button (click)="changeStatus(element, 'Unpublished')">
                  Unpublish
                </button>
              </div>
            </div>
            <div class="button-row" *ngIf="showAffiliatesControls">
              <div class="button-box">
                <button (click)="createLocalPackage(element)" color="primary" mat-raised-button>
                  Create local
                </button>
              </div>
            </div>
            <div *ngIf="currentViewMode === 'AFFILIATE'">
              <div class="button-box">
                <button *ngIf="element.status === 'Added'" (click)="changeStatus(element, 'Deployed')" color="primary" mat-raised-button>
                  Deploy
                </button>
                <button *ngIf="element.status === 'Deployed'" color="primary" mat-raised-button (click)="changeStatus(element, 'Added')">
                  Undeploy
                </button>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="button-row">
              <div class="button-box">
                <button (click)="editPackage(element)" color="primary" mat-raised-button>
                  {{ element.status === 'Deployed' || element.status === 'Published' || element.status === 'Unpublished' ? 'Info' : 'Edit' }}
                </button>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ disabled: row.disabled }" class="one-element-row"></mat-row>
      </mat-table>

      <div class="no-record-found" *ngIf="!dataSource">
        No Record found
      </div>
    </one-table-content>
  </one-table>
</div>
