<ng-container>
  <one-footer>
    <one-footer-content>
      <div class="dialog-footer-content">
        <div class="dialog-description">This information is proprietary and is intended as a reference and for internal use only. Do not share with external audiences.</div>
        <div class="dialog-copyright">© 2021 F. Hoffmann-La Roche Ltd</div>
        <div class="dialog-last-update">Last updated: 08.03.2021</div>
      </div>
    </one-footer-content>
  </one-footer>
</ng-container>
