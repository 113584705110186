import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './core/auth/auth.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserDataService } from './core/services/user-data.service';
import { OneSnackBarService, OneSnackBarType } from '@one/angular-kit/modal';
import { AppInitializerService } from './core/app-initializer/app-initializer.service';


enum View {
  Mobile,
  DesktopLoggedIn,
  DesktopLoggedOut
}

@Component({
  selector: 'dl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None, // TODO Can be deleted probably
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit {
  
  public view = View;
  public view$: Observable<View> = this.breakpointObserver.observe('(min-width: 600px)').pipe(
    map(result => result.matches),
    map((desktop: boolean) => {
      if (!desktop) {
        return View.Mobile;
      } else {
        if (this.authService.signIn) {
          return View.DesktopLoggedIn;
        } else {
          return View.DesktopLoggedOut;
        }
      }
      
    })
  );

  public constructor(private http: HttpClient, private breakpointObserver: BreakpointObserver, private authService: AuthService, private userDataService: UserDataService, private snackBarService: OneSnackBarService, private appInitializerService: AppInitializerService, /*private analyticsService: AnalyticsService, private countryLanguageService: CountryLanguageService, private regionDialogService: RegionDialogService, private availabilitiesService: AvailabilitiesService, private userEservices: UserEServicesService, private learnMoreService: LearnMoreDialogService, private liveChatService: LiveChatService, private getHelpTicketUtilService: GetHelpTicketUtilService, @Inject(ENVIRONMENT) private environment: EnvironmentInterface,*/ private router: Router) {}

  public ngOnInit(): void {
    //this.router.events.subscribe((nav) => window.scrollTo(0,0));
    try {
      this.userDataService.getUserRole();
    } catch (error) {
      this.authService.signIn;
    }
    this.userDataService.getAuthorized().subscribe(
      () => {},
      error => {
        this.snackBarService.open('Session has ended.\n You will be redirected to login page.', OneSnackBarType.WARNING);
        setTimeout(() => {
          this.authService.signIn;
        }, 3000);
      }
    );
  }

  public ngAfterViewInit(): void {}
}
