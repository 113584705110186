import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';

@Injectable()
export class ModalService {
  public dialogConfig = new MatDialogConfig();
  public baseDialogClass = 'custom-dialog';

  public constructor(public dialog: MatDialog) {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.position = { top: '30px' };
    this.dialogConfig.maxHeight = '90vh';
    this.dialogConfig.disableClose = true;
    this.dialogConfig.panelClass = this.baseDialogClass;
  }

  public showModal<T, D, R>(modalComponent: ComponentType<T>, data?: D, dialogConfig?: MatDialogConfig): MatDialogRef<T, R> {
    dialogConfig = { ...this.dialogConfig, ...dialogConfig };
    dialogConfig.data = data || {};
    return this.dialog.open<T>(modalComponent, dialogConfig);
  }
}
