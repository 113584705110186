import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UserDataService } from 'projects/dialog/src/app/core/services/user-data.service';
import { PackageType } from 'projects/dialog/src/app/shared/models/core.types';

@Component({
  selector: 'dl-script-dashboard',
  templateUrl: './script-dashboard.component.html',
  styleUrls: ['./script-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ScriptDashboardComponent implements OnInit {
  public packageType: PackageType = 'Assay';

  public constructor(public userDataService: UserDataService) {}

  public ngOnInit(): void {}
}
