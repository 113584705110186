import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { TranslatedBundleGuard } from '@dialog-eservices-enablement/angular-components';
import { AppEnablerFirstComponent } from './features/app-enabler/app-enabler-first/app-enabler-first.component';
import { ScriptDashboardComponent, SoftwareDashboardComponent } from './features/software/components';

export const appRouterModule: Routes = [
  {
    path: '',
    redirectTo: '/software-list',
    pathMatch: 'full'
  },
  // {
  //   path: 'app-enabler',
  //   component: AppEnablerFirstComponent,
  //   canActivate: [TranslatedBundleGuard],
  //   data: { trackWithAnalytics: true }
  // },
  {
    path: 'software-list',
    component: SoftwareDashboardComponent,
    //canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: 'script-list',
    component: ScriptDashboardComponent,
    //canActivate: [TranslatedBundleGuard],
    data: { trackWithAnalytics: true }
  },
  {
    path: '**',
    redirectTo: '/software-list',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRouterModule, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRouterModule {}
